<template>
  <b-card :title="titleTable">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-button
        v-if="showBtnAdd"
        variant="primary"
        data-cy="incluir"
        @click="add()"
      >
        Incluir
      </b-button>
      <slot name="buttons"></slot>
    </div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div v-if="showSearch" class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
            data-cy="search"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->
        <span v-if="props.column.field === 'ativo'">
          <b-badge :variant="statusVariant(props.row.ativo)">
            {{ props.row.ativo ? 'Sim' : 'Não' }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              data-cy="action"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="edit(props.row)" data-cy="edit-btn">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span data-cy="edit">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="showActionDelete"
                data-cy="delete"
                @click="remove(props.row)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'matricula'">
          {{ mascaraMatricula(props.row.matricula) }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="tableConfigs.size"
              :options="[tableConfigs.size]"
              class="mx-1"
              @input="handleChangePage"
            />
            <span class="text-nowrap">
              of {{ tableConfigs.totalPages }} entries
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="tableConfigs.totalElements"
              :per-page="tableConfigs.size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import _ from 'lodash'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton
  },
  mixins: [mixinsGlobal],
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    titleTable: {
      type: String,
      default: 'Basic Table'
    },
    sortName: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      required: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showBtnAdd: {
      type: Boolean,
      default: true
    },
    showActionDelete: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      totalRecords: 0,
      isLoading: false,
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      pageLength: 10,
      dir: false,
      searchTerm: '',
      status: [
        {
          1: 'true',
          2: 'false'
        },
        {
          1: 'light-primary',
          2: 'light-danger'
        }
      ]
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        true: 'light-primary',
        false: 'light-danger'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  mounted() {
    this.pageConfig.sort = this.sortName
    this.loadTable(this.module, this.pageConfig)
  },
  methods: {
    add() {
      this.$emit('add')
    },
    edit(data) {
      this.$emit('edit', data)
    },
    async remove(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(`${this.module}/delete`, data.id)
            .then((response) => {
              if (response.data.error) {
                this.MensagemError(
                  'Falha ao remover o registro',
                  response.data.message
                )
                return
              }

              if (response.error) {
                this.MensagemError(
                  'Falha ao remover o registro',
                  response.message
                )
                return
              }
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!'
              )
              this.pageConfig.sort = this.sortName
              this.loadTable(this.module, this.pageConfig)
            })
            .catch((err) => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.pageConfig.sort = this.sortName
              this.loadTable(this.module, this.pageConfig)
            })
        }
      })
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      this.loadTable(this.module, this.pageConfig)
    },
    loadTable(module, pageConfig) {
      this.isLoading = true
      this.$store
        .dispatch(`${module}/loadTable`, pageConfig)
        .then((result) => {
          this.pageConfig.page = result.data.pageable.pageNumber
          this.tableConfigs.last = result.data.last
          this.tableConfigs.totalPages = result.data.totalPages
          this.tableConfigs.totalElements = result.data.totalElements
          this.tableConfigs.size = result.data.size
          this.tableConfigs.number = result.data.number
          this.isLoading = false
        })
        .catch((err) => {
          this.MensagemError(
            'Falha ao carregar lista',
            `${err.response.data.error} / ${err.response.data.message}`
          )
          this.isLoading = false
        })
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      this.loadTable(this.module, this.pageConfig)
    },
    handleSearch: _.debounce(function (searching) {
      this.pageConfigdefault()
      if (searching) {
        this.pageConfig.filter = `nome=${searching}`
      }
      this.loadTable(this.module, this.pageConfig)
    }, 300),
    pageConfigdefault() {
      this.pageConfig = {
        check: false,
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    },
    mascaraMatricula(value) {
      let matriculaMascarada = value
      if (matriculaMascarada) {
        matriculaMascarada = matriculaMascarada.replace(/\D/g, '') // Remove o que não é digito
        if (matriculaMascarada.length <= 4) {
          matriculaMascarada = matriculaMascarada.replace(
            /(\d{3,4})?(\d{2})$/,
            '$1-$2'
          )
        } else if (matriculaMascarada.length <= 7) {
          matriculaMascarada = matriculaMascarada.replace(
            /(\d{3,4})?(\d{2})/,
            '$1-$2'
          )
        } else if (matriculaMascarada.length >= 8) {
          matriculaMascarada = matriculaMascarada.replace(
            /(\d{2})?(\d{4})?(\d{2})$/,
            '$1.$2-$3'
          )
        }
      }
      return matriculaMascarada
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
